import React from 'react';
import './Componen5TxT1Ima.css';

const SeccionTresColumnas = () => {
  return (
    <div className="contenedor-tres-columnas">
      <div className="columna Padd90">
        <div className="icono-seccion">
          <div className="icono-titulo margen0">
            <img src="./imagenes/SeguridadAlturas/flechalateral.png"  className="icono w3030" />
            <h2 className="titulo-grid2 margen0 blanco">Puntos de Anclaje Fijos</h2>
          </div>
          <div className='textDescrip'>Con certificación y calidad garantizada, somos la elección confiable para trabajos en altura. Protege y cumple con las normativas de seguridad.</div>
        </div>
        <div className="icono-seccion">
          <div className="icono-titulo margen0">
            <img src="./imagenes/SeguridadAlturas/flechalateral.png"  className="icono w3030" />
            <h2 className="titulo-grid2 margen0 blanco">Inspección y Certificación</h2>
          </div>
          <div className='textDescrip'>Realizamos inspecciones rigurosas y certificaciones para garantizar que tus equipos y sistemas de seguridad cumplan con los estándares de seguridad.</div>
        </div>
      </div>
      <div className="columna">
        <h2 className='tituloSS'>Seguridad en Alturas</h2>
        <div className="imagen">
            <img src="https://content.app-sources.com/s/8237597472581147/thumbnails/640x480/Images/4-2840123.jpg?format=webp" alt="Trabajo en alturas" />
        </div>
        <div className="icono-seccion margen40">
          <div className="icono-titulo margen0">
            <img src="./imagenes/SeguridadAlturas/flechalateral.png"  className="icono w3030" />
            <h2 className="titulo-grid2 margen0 blanco">Tienda EPP y EPI</h2>
          </div>
          <div className='textDescrip'>Encuentra todo lo necesario para la seguridad personal en altura en nuestra tienda especializada, con equipos de protección personal de alta calidad.</div>
        </div>
      </div>
      <div className="columna">
        <div className="icono-seccion">
          <div className="icono-titulo margen0">
            <img src="./imagenes/SeguridadAlturas/flechalateral.png"  className="icono w3030" />
            <h2 className="titulo-grid2 margen0 blanco">Líneas de Vida</h2>
          </div>
          <div className='textDescrip'>Protege a tu equipo con nuestras líneas de vida certificadas, diseñadas para ofrecer seguridad y tranquilidad en trabajos en altura con linea de vida horizontal y linea de vida vertical.</div>
        </div>
        <div className="icono-seccion">
          <div className="icono-titulo margen0">
            <img src="./imagenes/SeguridadAlturas/flechalateral.png"  className="icono w3030" />
            <h2 className="titulo-grid2 margen0 blanco">Sistemas de Acceso</h2>
          </div>
          <div className='textDescrip'>Proporcionamos sistemas de acceso seguros y eficientes, facilitando el trabajo en alturas de manera cómoda y protegida.</div>
        </div>
      </div>
    </div>
  );
};

export default SeccionTresColumnas;
