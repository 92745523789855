import React from 'react';
import './SeccionMitadDeImagenLadoIZQ.css';

const SeccionConImagen = ({ imagenURL, titulo, descripcion, textoBoton, url }) => {
  return (
    <div className="contenedor-seccion">
      <div className="lado-izquierdo">
        <img src={imagenURL} alt={titulo} className="imagen-seccion-LadoIZQ" />
      </div>
      <div className="lado-derecho">
        <h2 className="titulo-seccion">{titulo}</h2>
        <hr className="linea-amarilla" />
        <p className="descripcion-seccion">{descripcion}</p>
        <div>
            <a className='boton-seccion btnColor' type="button" href={url} target='_Blank'>{textoBoton}</a>
        </div>
      </div>
    </div>
  );
};

export default SeccionConImagen;
