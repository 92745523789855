import React from 'react';
import './gridTresPartesIconTitulo.css';
import './gridtreselementos.css'
import './Boton.css'

const GridContent = () => {
    return (
      <div className="grid-container-Icon">
        <h1 className="main-title-Icon">Servicios de Gestión Integral de Proyectos</h1>
        <div className="grid-item-Icon">
          <div className="icono-titulo">
            <img src="https://content.app-sources.com/s/24444282803329/uploads/A1/ecommerce_megaphone-5414287.png?format=webp"  className="icono" />
            <h2 className="titulo-grid2">Elaboración de Presupuestos</h2>
          </div>
          <p className="item-description-Icon">Estimación detallada de costos para tus proyectos, garantizando transparencia y viabilidad financiera.</p>
          <a href='https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+la+elaboraci%C3%B3n+de+Presupuestos.' className="boton-grid btnColor" target='_Blanck'>Obtener Presupuesto</a>
        </div>
        <div className="grid-item-Icon">
          <div className="icono-titulo">
            <img src="https://content.app-sources.com/s/24444282803329/uploads/A1/software_paintbrush-5414287.png?format=webp"  className="icono" />
            <h2 className="titulo-grid2">Programación y Control de Obras</h2>
          </div>
          <p className="item-description-Icon">Gestión eficiente del cronograma y control de avances para asegurar la ejecución oportuna de tus proyectos.</p>
          <a href='https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+la+programacion+y+control+de+obras.' className="boton-grid btnColor" target='_Blanck'>Control de Obras</a>
        </div>
        <div className="grid-item-Icon">
          <div className="icono-titulo">
            <img src="https://content.app-sources.com/s/24444282803329/uploads/A1/ecommerce_cart_cloud-5414287.png?format=webp"  className="icono" />
            <h2 className="titulo-grid2">Interventoría de Obras</h2>
          </div>
          <div className="item-description-Icon">Control técnico y administrativo para asegurar la excelencia y el cumplimiento de los requisitos en la realización de proyectos.</div>
          <a href='https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+interventoria+de+obras.' className="boton-grid btnColor" target='_Blanck'>Supervisión de Obras</a>
        </div>
      </div>
    );
  };
  
  export default GridContent;