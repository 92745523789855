import React from 'react';
import './gridtreselementos.css'; // Importamos el archivo CSS para los estilos

const GridContent = () => {
  return (
    <div className="grid-container">
      <h1 className="main-title">Servicios</h1>
      <div className="grid-item">
        <img src="/ObrasCiviles-J.jpg" alt="Imagen 1" className="item-image" />
        <h2 className="item-title">Obras Civiles</h2>
        <p className="item-description">Transformamos tus ideas en realidad. Nuestro servicio de Obras Civiles ofrece soluciones estructurales sólidas y eficientes para proyectos de cualquier escala. Confía en nuestra experiencia para construir tu futuro.</p>
      </div>
      <div className="grid-item">
        <img src="/Ingenieria-J.jpg" alt="Imagen 2" className="item-image" />
        <h2 className="item-title">Servicios de Ingeniería</h2>
        <p className="item-description">Potenciamos tu visión con experiencia y precisión. Nuestros Servicios de Ingeniería ofrecen soluciones innovadoras y personalizadas para cada desafío. Confiabilidad y excelencia en cada proyecto.</p>
      </div>
      <div className="grid-item">
        <img src="/SeguridadAlturas-J.jpg" alt="Imagen 3" className="item-image" />
        <h2 className="item-title">Seguridad en Alturas</h2>
        <p className="item-description">Elevando estándares de seguridad. Nuestro servicio de Seguridad en Alturas garantiza entornos protegidos y cumplimiento normativo. Confía en nosotros para salvaguardar vidas y proyectos.</p>
      </div>
    </div>
  );
};

export default GridContent;
