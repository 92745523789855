import React from 'react';
import './SeccionMitadDeImagenLadoDER.css';

const SeccionConImagen = ({ imagenURL, titulo, descripcion, textoBoton,url }) => {
  return (
    <div className="contenedor-seccion-lado-der">
      <div className="lado-derecho-lado-der">
        <h2 className="titulo-seccion-lado-der">{titulo}</h2>
        <hr className="linea-amarilla-lado-der" />
        <p className="descripcion-seccion-lado-der">{descripcion}</p>
        <div>
            <a  className='boton-seccion-lado-der btnColor' type="button" href={url} target='_Blank'>{textoBoton}</a>
        </div>
      </div>
      <div className="lado-izquierdo-lado-der">
        <img src={imagenURL} alt={titulo} className="imagen-seccion-lado-der"  />
      </div>
    </div>
  );
};

export default SeccionConImagen;
