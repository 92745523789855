import TituloConFondo from "../component/TituloConFondo"
import SeccionConImagen from "../component/SeccionMitadImagenIZQTxTCentrado"
import TxtCentrado from "../component/TxTCentrado"
import TxtCentradoColor from "../component/TxTCentradoColor"
import Grid2Por2 from '../component/Grid2X2'
import Footer from '../component/Footer'
import { Helmet } from "react-helmet";

const PuntodeAnclaje = () =>{
    return<div>
      <Helmet>
      <title>Puntos de Anclaje fijos certificados de Industek Colombia</title>
      <link rel="canonical" href="http://industek.com.co/Puntos-de-Anclaje"></link>
      <meta property="og:url" content="http://industek.com.co/Puntos-de-Anclaje"></meta>
      <meta itemprop="url" content="http://industek.com.co/Puntos-de-Anclaje"></meta>
      <meta name="twitter:url" content="http://industek.com.co/Puntos-de-Anclaje"></meta>
      <meta name="description" content="Industek S.A.S Colombia ofrece servicios integrales de instalación, inspección y certificación de puntos de anclaje para trabajos en alturas, cumpliendo con la normativa legal vigente en Colombia, incluida la RESOLUCIÓN 1409 DE 2012."></meta>
      <meta property="og:site_name" content="Industek Lineas de vida y puntos de anclaje"></meta>
      <meta property="og:title" content="Puntos de Anclaje fijos certificados de Industek Colombia"></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:description" content="Industek S.A.S Colombia ofrece servicios integrales de instalación, inspección y certificación de puntos de anclaje para trabajos en alturas, cumpliendo con la normativa legal vigente en Colombia, incluida la RESOLUCIÓN 1409 DE 2012."></meta>
      <meta itemprop="name" content="Puntos de Anclaje fijos certificados de Industek Colombia"></meta>
      <meta itemprop="description" content="Industek S.A.S Colombia ofrece servicios integrales de instalación, inspección y certificación de puntos de anclaje para trabajos en alturas, cumpliendo con la normativa legal vigente en Colombia, incluida la RESOLUCIÓN 1409 DE 2012."></meta>
      <meta name="twitter:title" content="Puntos de Anclaje fijos certificados de Industek Colombia"></meta>
      <meta name="twitter:card" content="summary"></meta>
      <meta name="twitter:description" content="Industek S.A.S Colombia ofrece servicios integrales de instalación, inspección y certificación de puntos de anclaje para trabajos en alturas, cumpliendo con la normativa legal vigente en Colombia, incluida la RESOLUCIÓN 1409 DE 2012."></meta>

      </Helmet>
      <TituloConFondo 
        titulo="Punto De Anclaje" 
      />
      <SeccionConImagen
        imagenURL="./imagenes/SeguridadAlturas/Anclaje o linea.jpeg"
        titulo="Puntos de Anclaje Fijos"
        descripcion="Garantizamos puntos de anclaje seguros y confiables para tus trabajos en altura, cumpliendo con los estándares de seguridad más exigentes."
        textoBoton="Cotizar Anclajes"
        url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenos+Dias+estamos+interesados+en+conocer+mas+sobre+Puntos+de+Anclaje."
      />
      <TxtCentrado
        empresa = "INDUSTEK" 
        titulo = "Puntos de Anclaje Certificados"
        descripcion ="Los dispositivos de Seguridad Grupal y Sistemas Antiaccidentes que nuestra empresa, Industek, implemente en las diversas áreas, estarán en conformidad con lo estipulado en la RESOLUCIÓN 1409 del 2012."
      />
      <TxtCentradoColor
        titulo = "Puntos de Anclaje de calidad"
        descripcion ="Nuestros sistemas de anclaje son la columna vertebral de la seguridad en altura. Diseñados con los más altos estándares de calidad, ofrecemos una amplia gama de opciones certificadas y probadas para garantizar la protección de su equipo. Desde sistemas fijos hasta sistemas portátiles, nuestra variedad asegura la adaptabilidad a cualquier entorno de trabajo. La instalación de sistemas confiables es crucial para salvaguardar la vida de sus trabajadores y cumplir con las normativas de seguridad vigentes. Con nuestras soluciones, usted puede confiar en una protección robusta y duradera en cada proyecto. La versatilidad de nuestros sistemas los hace ideales para una variedad de aplicaciones, desde la construcción hasta la industria petrolera. En Industek, nos comprometemos a brindar soluciones que superen las expectativas de seguridad y rendimiento. Confíe en nuestra experiencia y dedicación para proporcionarle las mejores opciones del mercado. No comprometa la seguridad de su equipo; elija nuestras soluciones y mantenga la tranquilidad en cada trabajo en altura."
      />
      <Grid2Por2/>
      <TxtCentrado
        titulo = "Certificación Puntos De Anclaje Colombia"
        descripcion ="Certificamos puntos de anclaje en Colombia para garantizar seguridad en trabajos en altura. Cumplimos normativas vigentes para proteger a tu equipo."
      />
      <Footer />

    </div>
}
export default PuntodeAnclaje